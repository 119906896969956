import React, { useState } from "react";
import {Alert, Form, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import axios from "axios/index"; 

const baseURL = "https://yogamodeladores.com.br/api";
//const baseURL = "https://api.yogamodeladores.com:9895";
//const baseURL = "http://localhost:9895";

function VerificarCupom() {

    const [cpfCnpj, setCpfCnpj] = useState("");
    const [mask, setMask] = useState("");
    const [estado, setEstado] = useState(0);
    const [botao, setBotao] = useState(true);
    const [lastCPF, setLastCPF] = useState('');
    const [dtUso, setDtUso] = useState('Não Disponível');

    async function getData(cpf) {
        try {
            const resultado = await axios.get(baseURL + '/show.php?cpf='+cpf);          
            console.log(resultado.data.data);
            if (resultado.data.data) {
                   const dt = new Date(resultado.data.data);            
        //            setDtUso(dt.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }));
                   return(dt.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }));
             }
        }
        catch(e) {
            console.log(e);
            return(null);
        }
    }

    async function confirmCPF(cpf) {
        try {
          const nrocpf = cpf.replace(/[^\d]+/g,'');
          const data =  { "cpf" : nrocpf };            

          const dt = await getData(nrocpf);
          //verificando se CPF já foi utilizado
          if (dt) {
                setDtUso(dt);
                return(false);
          }
          else {
                //inserindo CPF para utilização de cupom hoje
                const response = await axios.post(baseURL + '/create.php', data);
                console.log(response);
                if (response.status===201)
                    return true;
                else
                    return false;         
          }

        } catch (error) {
          console.error(error);
          return false;
        } 
    }

    function submit_handler(event) {    
        event.preventDefault();

        console.log('rodou submit_handler', estado);

        let tmpEstado = 0;
        //valida CPF
        if (validarCPF(cpfCnpj)) {
            tmpEstado = 1;

            //verifica se o CPf já foi utilizado
            confirmCPF(cpfCnpj)
            .then((confirmado) => {
                if (confirmado) 
                   tmpEstado = 2;                
                else
                   tmpEstado = -2;
                setLastCPF(cpfCnpj);
                setCpfCnpj('');
                setEstado(tmpEstado);
                })
            .catch((err) => {
                tmpEstado = -2;
                console.log(err);  
            });            
        }
        else {
            tmpEstado = -1;
            setEstado(tmpEstado);
        }        
    }

    const validarCPF = (cpf) => {	
        cpf = cpf.replace(/[^\d]+/g,'');	
        var rev = 0;
        if(cpf === '') return false;	
        // Elimina CPFs invalidos conhecidos	
        if (cpf.length !== 11 || 
            cpf === "00000000000" || 
            cpf === "11111111111" || 
            cpf === "22222222222" || 
            cpf === "33333333333" || 
            cpf === "44444444444" || 
            cpf === "55555555555" || 
            cpf === "66666666666" || 
            cpf === "77777777777" || 
            cpf === "88888888888" || 
            cpf === "99999999999")
                return false;		
        // Valida 1o digito	
        var add = 0;	
        for (var i=0; i < 9; i ++)		
            add += parseInt(cpf.charAt(i)) * (10 - i);	
            rev = 11 - (add % 11);	
            if (rev === 10 || rev === 11)		
                rev = 0;	
            if (rev !== parseInt(cpf.charAt(9)))		
                return false;		
        // Valida 2o digito	
        add = 0;	
        for (i = 0; i < 10; i ++)		
            add += parseInt(cpf.charAt(i)) * (11 - i);	
        rev = 11 - (add % 11);	
        if (rev === 10 || rev === 11)	
            rev = 0;	
        if (rev !== parseInt(cpf.charAt(10)))
            return false;		
        return true;   
    }


    return(
        <section>
            <h3 className="p-3">Informe o CPF do cliente abaixo</h3>
            <Form 
                className="form-group"
                onSubmit={submit_handler}    
            >
                <div>
                    <CpfCnpj 
                        className="myCPF"
                        value={cpfCnpj}
                        onChange={(event, type) => {
                        setCpfCnpj(event.target.value);
                        setMask(type === "CPF");
                        let tmpEstado=0;
                        if (event.target.value.replaceAll('.','').replaceAll('-','').length<11)
                            tmpEstado = 0;
                        else 
                        if (validarCPF(event.target.value))
                            tmpEstado = 1;
                        else
                            tmpEstado = -1;
                        
                        if (estado!==tmpEstado) {
                            setEstado(tmpEstado);
                            if (botao && tmpEstado===1)
                                setBotao(false);
                            else if (!botao)
                                setBotao(true);
                        }        
                        }}
                    /></div>
                <div>
                    <Button 
                        className="myCPF m-3"  
                        variant="warning"   
                        type="submit"
                        disabled={botao}
                    >
                        Validar
                    </Button>
                </div>
            </Form>

            {estado>=0 ?
                (estado===1 ?  
                    <Alert key='resOs' variant='warning'>
                        Clique no botão Validar para verificar se o cupom está disponível para este CPF e baixar automaticamente!
                    </Alert>
                :                  
                    (estado===2 ?
                        <Alert key='resOs' variant='success'>
                            Cupom de Desconto de R$25 liberado para o CPF <strong>{lastCPF}</strong> na compra acima de R$150 (limite de um cupom por CPF).
                        </Alert>            
                    :
                        <span></span>
                    )
                )
            :
                (estado===-1 ?
                    <Alert key='resErr' variant='danger'>
                        CPF inválido!
                    </Alert>
                :
                    <Alert key='resErr' variant='danger'>
                        Cupom já utilizado para este CPF !<br></br>
                        Data de uso: {dtUso}
                    </Alert>
                )
            }
        </section>
      
    );
}




export default VerificarCupom;